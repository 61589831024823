const header = document.querySelector('.header');
const headerNavigation = document.querySelector('.header__navigation');
const headerServiceNavigation = document.querySelector('.header__service-navigation');
const headerNavigationToggle = document.querySelector('.header__navigation-toggle');
const headerSearchForm = document.querySelector('.header__search-form');

function openSearch() {
  header.classList.add('header--search-open');

  // Close open mobile nav when search is active
  if (headerNavigation.classList.contains('is-active')) {
    headerNavigation.classList.remove('is-active');
    headerServiceNavigation.classList.remove('is-active');
    headerNavigationToggle.classList.remove('is-active');
  }
}

function closeSearch() {
  header.classList.remove('header--search-open');
}

let searchSuggestions;

function autocomplete(inp, arr) {
  /* The autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values: */

  /* Execute a function when someone writes in the text field: */
  inp.addEventListener('input', function () {
    if (inp.value.length >= 3) {
      /* Get url for bloomreach json */
      let url = headerSearchForm.dataset.bloomsearchUrl;
      fetch(url + inp.value)
        .then((response) => response.json())
        .then((responseJson) => {
          let divElement;
          let divInnerElement;
          let divElementItem;
          let divBtn;
          let val = this.value;
          /* Close any already open lists of autocompleted values */
          closeAllLists();
          if (!val) {
            return false;
          }

          /* Create a DIV element that will contain the items (values): */
          divElement = document.createElement('DIV');
          divInnerElement = document.createElement('DIV');
          divBtn = document.createElement('DIV');
          divElement.setAttribute('class', 'autocomplete-list');
          divInnerElement.setAttribute('class', 'autocomplete-items');
          divBtn.setAttribute('class', 'button--show-more');
          /* Append the DIV element as a child of the autocomplete container: */
          this.parentNode.appendChild(divElement);
          arr = responseJson.suggestionGroups[0].searchSuggestions;
          const topResults = headerSearchForm.dataset.topResults;

          if (arr && arr.length > 0) {
            divElement.innerHTML = `<p>${topResults} <b>${inp.value}</b></p>`;
            /* Show only first 6 items from json */
            const searchSuggestionsSix = arr.slice(0, 6);

            searchSuggestionsSix.forEach((searchSuggestion) => {
              /* Create a DIV element for each matching element: */
              divElementItem = document.createElement('DIV');

              /* Create html markup for each item: */
              divElementItem.innerHTML += `
              <a href='${searchSuggestion.url}' class="autocomplete-item">
                <div class="autocomplete-item--image">
                  <img class="lazyload" src='${searchSuggestion.thumb_image}' alt="${searchSuggestion.title}" title="${searchSuggestion.title}" height="65" width="65">
                </div>
                <div>
                  <p>${searchSuggestion.title}</p>
                  <p>${searchSuggestion.shortDescription}</p>
                </div>
              </a>`;

              divElement.appendChild(divInnerElement);
              divInnerElement.appendChild(divElementItem);
            });

            /* Create markup for show all button: */
            const showAllResults = headerSearchForm.dataset.showAll;
            divBtn.innerHTML = `<div class="btn btn--red btn--md">${showAllResults}</div>`;
            divElement.append(divBtn);
          } else {
            closeAllLists();
          }

          /* Execute a function when someone clicks on show all button: */
          const showAllElements = document.querySelector('.button--show-more');
          if (showAllElements) {
            showAllElements.addEventListener('click', function () {
              /* Submit value for the autocomplete: */
              this.closest('form').submit();
              /* Close the list of autocompleted values,
                (or any other open lists of autocompleted values: */
              closeAllLists();
            });
          }
        });
    } else {
      closeAllLists();
    }
  });

  function closeAllLists(elmnt) {
    /* Close all autocomplete lists in the document,
    except the one passed as an argument: */
    const itemsList = document.querySelectorAll('.autocomplete-list');
    itemsList.forEach((item) => {
      if (elmnt !== item && elmnt !== inp) {
        item.parentNode.removeChild(item);
      }
    });
  }

  /* Execute a function when someone clicks in the document: */
  document.addEventListener('click', function (e) {
    closeAllLists(e.target);
  });
}

const inputField = document.querySelector('.header__search-input');
if (inputField) {
  autocomplete(inputField, searchSuggestions);
}

export { openSearch, closeSearch };
