import lazySizes from 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/respimg/ls.respimg';

/**
 * TODO: Rename this component to lazyload?
 */

/**
 * Configure native lazyloading
 * https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/native-loading
 */
// lazySizes.cfg.nativeLoading = {
//   setLoadingAttribute: true
// };

/**
 * Configure lazysizes background image plugin
 * https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/bgset
 */
lazySizes.cfg.customMedia = {
  '--xs': '(max-width: 575px)',
  '--sm': '(max-width: 767px)',
  '--md': '(max-width: 991px)',
  '--lg': '(max-width: 1199px)',
  '--xl': '(min-width: 1200px)',
};
