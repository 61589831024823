'use strict';
// Load jQuery Plugins because they're in global namespace :(
import 'bootstrap';

// Import 'core-js/modules/es6.promise';
// import 'core-js/modules/es6.array.iterator';
// ###### Import jQuery ######
import 'expose-loader?$!jquery';
import 'expose-loader?jQuery!jquery';
import './libs/public-path';
import './libs/lazyload';

// Custom Components
import './_components';

// Allows different styling with/without javascript support
document.querySelector('html').classList.remove('no-js');
document.querySelector('html').classList.add('js');
