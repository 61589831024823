/**
 * Static imports always required! ###
 */
import '../../components/a/icon';
import '../../components/o/header';
// Import '../../components/a/select';

const isThere = (selector) => document.querySelector(selector) !== null;

/**
 * Dynamic imports splited in chunks
 */
if (isThere('.select')) {
  import(/* webpackChunkName: "select" */ '../../components/a/select');
}

if (isThere('.datepicker')) {
  import(/* webpackChunkName: "datepicker" */ '../../components/a/datepicker');
}

if (isThere('.keyvisual-slider')) {
  import(/* webpackChunkName: "keyvisual-slider" */ '../../components/o/keyvisual/keyvisual-slider');
}

if (isThere('.keyvisual--parallax')) {
  import(/* webpackChunkName: "keyvisual--parallax" */ '../../components/o/keyvisual/keyvisual--parallax');
}

if (isThere('.highlight-categories')) {
  import(/* webpackChunkName: "highlight-categories" */ '../../components/o/highlight-categories');
}

if (isThere('.highlight-teaser')) {
  import(/* webpackChunkName: "highlight-teaser" */ '../../components/o/highlight-teaser');
}

if (isThere('.highlight-teaser-v2')) {
  import(/* webpackChunkName: "highlight-teaser-v2" */ '../../components/o/highlight-teaser-v2');
}

if (isThere('.two-column-text-media-slider') || isThere('.two-column-text-media')) {
  import(/* webpackChunkName: "two-column-text-media-slider" */ '../../components/m/two-column-text-media');
}

if (isThere('.two-column-square-media-teaser')) {
  import(/* webpackChunkName: "two-column-square-media-teaser" */ '../../components/m/two-column-square-media-teaser');
}

if (isThere('.product-slider')) {
  import(/* webpackChunkName: "product-slider" */ '../../components/m/product-slider');
}

if (isThere('.flex-media-teaser')) {
  import(/* webpackChunkName: "flex-media-teaser" */ '../../components/m/flex-media-teaser');
}

if (isThere('.flex-icon-teaser')) {
  import(/* webpackChunkName: "flex-icon-teaser" */ '../../components/m/flex-icon-teaser');
}

if (isThere('.filter-accordion')) {
  import(/* webpackChunkName: "filter-accordion" */ '../../components/m/filter-accordion');
}

if (isThere('.kit-teaser')) {
  import(/* webpackChunkName: "kit-teaser" */ '../../components/o/kit-teaser');
}

if (isThere('.modal--video')) {
  import(/* webpackChunkName: "modal-video" */ '../../components/m/modal');
}

if (isThere('.media-gallery')) {
  import(/* webpackChunkName: "media-gallery" */ '../../components/m/media-gallery');
}

if (isThere('.show-more')) {
  import(/* webpackChunkName: "show-more" */ '../../components/a/show-more');
}

if (isThere('.review-bar')) {
  import(/* webpackChunkName: "review-bar" */ '../../components/o/review-bar');
}

if (isThere('.media-thumb')) {
  import(/* webpackChunkName: "media-thumb" */ '../../components/m/media-thumb');
}

if (isThere('.simple-media-gallery')) {
  import(/* webpackChunkName: "simple-media-gallery" */ '../../components/o/simple-media-gallery');
}

if (isThere('.accordion')) {
  import(/* webpackChunkName: "accordion" */ '../../components/m/accordion');
}

if (isThere('.tab-navigation-slider')) {
  import(/* webpackChunkName: "tab-navigation-slider" */ '../../components/m/tab-navigation-slider');
}

if (isThere('.table')) {
  import(/* webpackChunkName: "table" */ '../../components/m/table-container');
}

if (isThere('.technical-data')) {
  import(/* webpackChunkName: "technical-data" */ '../../components/m/technical-data');
}

if (isThere('.product-catalog')) {
  import(/* webpackChunkName: "product-catalog" */ '../../components/o/product-catalog');
}

if (isThere('.search-result')) {
  import(/* webpackChunkName: "search-result" */ '../../components/o/search-result');
}

if (isThere('.product-table')) {
  import(/* webpackChunkName: "product-table" */ '../../components/o/product-table');
}

if (isThere('.compare')) {
  import(/* webpackChunkName: "compare" */ '../../components/o/compare');
}

if (isThere('.two-column-table-text')) {
  import(/* webpackChunkName: "two-column-table-text" */ '../../components/o/two-column-table-text');
}

if (isThere('.anchor')) {
  import(/* webpackChunkName: "anchor" */ '../../components/m/anchor');
}

if (isThere('.chat-now')) {
  import(/* webpackChunkName: "chat-now" */ '../../components/m/chat-now');
}

if (isThere('.hotspots')) {
  import(/* webpackChunkName: "hotspots" */ '../../components/m/hotspots');
}

if (isThere('.e-repair-status')) {
  import(/* webpackChunkName: "erepair" */ '../../components/o/erepair');
}

if (isThere('#customer-service-modal')) {
  import(/* webpackChunkName: "customer-service-modal" */ '../../components/m/smsText');
}

if (isThere('#lboxx-popover')) {
  import(/* webpackChunkName: "lboxx-popover" */ '../../components/o/lboxx-popover');
}

if (isThere('.highlight-product-feature-stage')) {
  import(
    /* WebpackChunkName: "highlight-product-feature-stage" */ '../../components/o/highlight-product-feature-stage'
  );
}

if (isThere('.highlight-keyvisual')) {
  import(/* WebpackChunkName: "highlight-keyvisual" */ '../../components/o/highlight-keyvisual');
}

if (isThere('.highlight-product-stage-slider')) {
  import(/* webpackChunkName: "highlight-product-stage-slider" */ '../../components/m/highlight-product-stage-slider');
}

if (isThere('.cliplister-360')) {
  import(/* webpackChunkName: "cliplister-360" */ '../../components/m/cliplister-360');
}

if (isThere('.toggle-teaser')) {
  import(/* webpackChunkName: "toggle-teaser" */ '../../components/o/toggle-teaser');
}

if (isThere('.one-column-media')) {
  import(/* webpackChunkName: "one-column-media" */ '../../components/m/one-column-media');
}

if (isThere('.keyvisual')) {
  import(/* webpackChunkName: "keyvisual" */ '../../components/o/keyvisual');
}

if (isThere('.Video-embedded')) {
  import(/* webpackChunkName: "video-embedded" */ '../../components/a/video');
}

if (isThere('.product-slider-modal')) {
  import(/* webpackChunkName: "product-slider-modal" */ '../../components/m/product-slider-modal');
}

if (isThere('.device-detection')) {
  import(/* webpackChunkName: "chat-now" */ '../../components/a/device-detection');
}
