import './index.scss';
import * as headerSearch from './header__search';
import * as headerNavigation from './header__navigation';

const header = document.querySelectorAll('.header');
const mql = window.matchMedia('(min-width: 992px)').matches;

if (document.querySelector('.header__breadcrumb')) {
  import(/* webpackChunkName: "breadcrumb" */ './header__breadcrumb');
}

/**
 * Add Class is-sticky if Main Navigation sticky
 */
const navSticky = new IntersectionObserver(
  function (entries) {
    // No intersection
    if (entries[0].intersectionRatio === 0) {
      entries[0].target.nextElementSibling.classList.add('is-sticky');
    }
    // Fully intersects
    else if (entries[0].intersectionRatio === 1) {
      entries[0].target.nextElementSibling.classList.remove('is-sticky');
    }
  },
  {
    threshold: [0, 1],
  }
);

/**
 *
 * @param {string} id
 * @param {string} notLoggedinUserName
 * @param {string} remoteApiUrl
 * @param {string} profileLinkUrl
 * Check if User is logged in and Show Username instead of Login text
 * function Call in HTML Page
 * e.g.: getCurrentErepairUser("userProfile","1-877 Bosch-99","https://www.boschtools.com/boschtools-erepair/rest/user/current-user", "https://www.boschtools.com/boschtools-erepair/update-profile");
 */

global.getCurrentErepairUser = function (id, notLoggedinUserName, remoteApiUrl, profileLinkUrl) {
  let username = notLoggedinUserName;

  $.getJSON(remoteApiUrl, function () {})
    .done(function (data) {
      $.each(data, function (key, val) {
        if (key === 'email') {
          username = val;
        }
      });
      $('#' + id).html('<a class="header__service-navigation-link" href="' + profileLinkUrl + '">' + username + '</a>');
    })
    .fail(function () {
      $('#' + id).html('<a class="header__service-navigation-link" href="' + profileLinkUrl + '">' + username + '</a>');
    });
};

header.forEach((element) => {
  const headerTop = element.querySelector('.header__top');
  const headerMiddle = element.querySelector('.header__middle');
  const headerSearchinput = element.querySelector('.header__search-input');
  const headerSearchForm = element.querySelector('.header__search-form');
  const headerSearchClose = element.querySelector('.header__search-button--close');
  const headerIconNavigationLinkSearch = element.querySelector('.header__icon-navigation-link--search');
  const flyoutClose = element.querySelectorAll('.header__flyout-button--close');
  const flyout = element.querySelectorAll('.header__flyout--first-level');
  const toggleFlyout = element.querySelectorAll(
    `
      .header__navigation-link--has-flyout,
      .header__flyout-navigation-link--has-flyout,
      .header__flyout-navigation-link--has-promotion,
      .header__flyout-button--back
    `
  );
  const navigationToggle = element.querySelectorAll('.header__navigation-toggle');

  if (headerMiddle) {
    navSticky.observe(headerTop);
  }

  if (headerSearchClose) {
    headerSearchClose.addEventListener('click', (e) => {
      const form = e.currentTarget.closest('form');
      if (form) {
        form.reset();
        // HeaderSearch.closeSearch();
      }
    });
  }

  if (headerSearchForm) {
    headerSearchForm.addEventListener(
      'focus',
      () => {
        // HeaderSearch.openSearch();
        if (!mql) {
          navigationToggle.forEach((el) => {
            headerNavigation.mobileFlyout();
            document.body.removeAttribute('style');
            headerMiddle.classList.remove('is-open');
            el.classList.remove('is-active');
            el.setAttribute('aria-expanded', false);
          });
        }
      },
      true
    );
    // HeaderSearchForm.addEventListener('blur', () => headerSearch.closeSearch(), false);
  }

  if (headerSearchinput && headerIconNavigationLinkSearch) {
    headerIconNavigationLinkSearch.addEventListener('click', function (event) {
      event.preventDefault();
      headerSearchinput.focus();
    });
  } else if (headerIconNavigationLinkSearch) {
    headerIconNavigationLinkSearch.addEventListener('click', function (event) {
      event.preventDefault();
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    });
  }

  if (mql) {
    // !Desktop
    // close button inside of flyout
    flyoutClose.forEach((element) => {
      element.addEventListener('click', function (event) {
        event.preventDefault();

        // Get focused element eg. close button
        const focusedEl = document.activeElement;

        // Get flyout of the clicked close button and search for the main link
        // which toggles the flyout. Then set the focus to this element and
        // remove the tabindex attribute to restore the natural focus order
        const flyout = focusedEl.closest('.header__flyout');
        const flyoutLink = flyout.parentNode.querySelector('.header__navigation-link');
        flyoutLink.removeAttribute('tabindex');
        flyoutLink.setAttribute('aria-expanded', 'false');
        flyoutLink.focus();

        // Get sub-links of the current flyout and disable keyboard focus
        const flyoutLinks = flyout.querySelectorAll('[tabindex="0"]');
        for (let i = 0; i < flyoutLinks.length; i++) {
          flyoutLinks[i].setAttribute('tabindex', '-1');
        }

        // Re-activate main navigation links for keyboard usage
        const mainLinks = document.querySelectorAll('.header__navigation-link');
        for (let i = 0; i < mainLinks.length; i++) {
          mainLinks[i].removeAttribute('tabindex');
        }

        headerNavigation.killFlyout();
      });
    });

    flyout.forEach((element) => {
      element.addEventListener('mouseleave', function () {
        // Reset aria-expanded attributes if flyout was closed
        const mainLinks = element.parentNode.querySelectorAll('a[aria-expanded="true"]');
        if (mainLinks !== null) {
          for (let i = 0; i < mainLinks.length; i++) {
            mainLinks[i].setAttribute('aria-expanded', 'false');
          }
        }

        headerNavigation.killFlyout();
      });
    });
  }

  navigationToggle.forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();
      this.classList.toggle('is-active');
      if (this.classList.contains('is-active')) {
        headerNavigation.mobileFlyout(true);
        document.body.style.overflowY = 'hidden';
        headerMiddle.classList.add('is-open');
        this.setAttribute('aria-expanded', true);
      } else {
        headerNavigation.mobileFlyout();
        document.body.removeAttribute('style');
        headerMiddle.classList.remove('is-open');
        this.setAttribute('aria-expanded', false);
      }
    });
  });

  /**
   * Main Navigation Flyout
   */
  toggleFlyout.forEach((element) => {
    element.addEventListener('click', function (event) {
      event.preventDefault();

      // Set proper attribute to indicate to screen-
      // readers that the flyout are open for now
      const link = event.target.closest('a');
      if (link !== null) {
        link.setAttribute('aria-expanded', 'true');
      }

      let links = null;
      // Get all links/buttons which should be clickable on the first-level
      if (event.target.classList.contains('header__navigation-link')) {
        links = event.target.parentNode.querySelectorAll(
          'a.header__flyout-navigation-link--first-level, .header__flyout-title, .header__flyout-button--close'
        );
      }

      // Get all links/buttons which should be clickable on the second-level
      if (event.target.classList.contains('header__flyout-navigation-link--first-level')) {
        links = event.target.parentNode.querySelectorAll('a.header__flyout-navigation-link--second-level');
      }

      // Get all links/buttons which should be clickable on the third-level
      if (event.target.classList.contains('header__flyout-navigation-link--second-level')) {
        links = event.target.parentNode.querySelectorAll(
          'a.header__flyout-navigation-link--second-level ~ .header__flyout .header__promotion-overlay > a, a.header__flyout-navigation-link--third-level'
        );
      }

      // Iterate over the elements and activate them, by
      // setting the tabindex accordingly
      if (links !== null) {
        for (let i = 0; i < links.length; i++) {
          links[i].setAttribute('tabindex', '0');
        }
      }

      if (mql) {
        headerNavigation.navigateTo(this);
      } else {
        headerNavigation.slideTo(this);
      }
    });
  });
});
