const navigation = document.querySelector('.header__navigation');
const serviceNavigation = document.querySelector('.header__service-navigation');
const navigationToggle = document.querySelectorAll('.header__navigation-toggle');

/**
 *
 * @param {Boolean} show
 */
function mobileFlyout(show) {
  if (show) {
    navigation.classList.add('is-active');
    serviceNavigation.classList.add('is-active');
    navigationToggle.forEach((element) => {
      element.classList.add('is-active');
    });
  } else {
    navigation.classList.remove('is-active');
    serviceNavigation.classList.remove('is-active');
    navigationToggle.forEach((element) => {
      element.classList.remove('is-active');
    });
  }
}

function killFlyout() {
  navigation.querySelectorAll('.is-active').forEach((element) => {
    element.classList.remove('is-active');
  });
}

/**
 *
 * @param {HTMLElement} element
 */
function getSiblings(element) {
  // Setup siblings array and get the first sibling
  var siblings = [];
  var sibling = element.parentNode.firstChild;

  // Loop through each sibling and push to the array
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== element) {
      siblings.push(sibling);
    }

    sibling = sibling.nextSibling;
  }

  return siblings;
}

/**
 *
 * @param {HTMLElement} element
 */
function setFlyoutHeight(element) {
  const source = element.parentElement.querySelectorAll(
    '.header__flyout-navigation-items--second-level, .header__flyout-navigation-items--third-level, .header__flyout--promotion'
  );
  const target = element.closest('.header__flyout--first-level');
  const heading = target.querySelector('h3');
  const headingStyle = heading.currentStyle || window.getComputedStyle(heading);
  const heights = [];

  source.forEach((elem) => heights.push(elem.offsetHeight < 350 ? 350 : elem.offsetHeight));

  target.style.minHeight = `${
    Math.max(...heights) +
    parseFloat(getComputedStyle(target).paddingTop) +
    parseFloat(getComputedStyle(target).paddingBottom) +
    heading.offsetHeight +
    parseFloat(headingStyle.marginBottom)
  }px`;
}

/**
 *
 * @param {HTMLElement} element
 */
function slideTo(element) {
  const id = document.getElementById(element.dataset.flyoutId);
  const navigation = document.querySelector('.header__navigation');
  const serviceNavigation = document.querySelector('.header__service-navigation');
  const flyoutLevel1 = document.querySelectorAll('.header__flyout--first-level');
  const mobileWrapper = document.querySelector('.header__navigation-mobile-wrapper');
  const siblings = getSiblings(element.parentElement);

  function goBack() {
    id.classList.remove('is-active');
    if (element.parentElement.classList.contains('header__flyout-navigation-item--first-level')) {
      navigation.style.left = '0';
      serviceNavigation.style.left = '0';
      mobileWrapper.removeAttribute('style');
    } else if (element.parentElement.classList.contains('header__flyout-navigation-item--second-level')) {
      navigation.style.left = '-100%';
    } else {
      navigation.style.left = '-200%';
    }
  }

  switch (true) {
    case element.classList.contains('header__flyout-button--back'):
      goBack();

      break;
    case element.classList.contains('header__flyout-navigation-link--first-level'):
      id.classList.add('is-active');
      navigation.style.left = '-200%';

      break;
    case element.classList.contains('header__flyout-navigation-link--second-level'):
      id.classList.add('is-active');
      navigation.style.left = '-300%';

      break;
    default:
      id.classList.add('is-active');

      flyoutLevel1.forEach((elem) => {
        elem.classList.remove('is-hidden');
      });

      siblings.forEach((element) => {
        element.querySelectorAll('.header__flyout--first-level').forEach((elem) => {
          elem.classList.add('is-hidden');
        });
      });

      navigation.style.left = '-100%';
      serviceNavigation.style.left = '-100%';

      break;
  }
}

/**
 *
 * @param {HTMLElement} element
 */
function navigateTo(element) {
  const level1 = element.classList.contains('header__flyout-navigation-link--first-level');
  const level2 = element.classList.contains('header__flyout-navigation-link--second-level');
  const level3 = element.classList.contains('header__flyout-navigation-link--third-level');

  function disableLink(lvl) {
    document.querySelectorAll(`.header__flyout-navigation-link--${lvl}-level`).forEach((element) => {
      element.classList.remove('is-active');
    });
  }

  function disableFlyout(lvl) {
    document.querySelectorAll(`.header__flyout--${lvl}-level.is-active`).forEach((element) => {
      element.classList.remove('is-active');
    });
  }

  function disablePromotion(lvl) {
    document.querySelectorAll(`.header__flyout--promotion-${lvl}-level.is-active`).forEach((element) => {
      element.classList.remove('is-active');
    });
  }

  function activateFlyout() {
    if (element.parentElement.querySelector('.header__flyout')) {
      element.parentElement.querySelector('.header__flyout').classList.add('is-active');
    }

    document.querySelectorAll('.header__flyout--promotion').forEach((element) => {
      element.classList.remove('is-active');
    });

    if (element.parentElement.querySelector(':scope > .header__flyout--promotion')) {
      element.parentElement.querySelector(':scope > .header__flyout--promotion').classList.add('is-active');
    }

    element.classList.add('is-active');
  }

  switch (true) {
    case element.classList.contains('header__flyout-navigation-link'):
      if (level1) {
        setFlyoutHeight(element);
        disableLink('first');
        disablePromotion('first');
        disableFlyout('second');
      } else if (level2) {
        disableLink('second');
        disablePromotion('second');
        disableFlyout('third');
      } else if (level3) {
        disableLink('third');
        disablePromotion('third');
      } else {
        element.parentElement.parentElement.querySelectorAll('.header__flyout-navigation-link').forEach((element) => {
          element.classList.remove('is-active');
        });
      }

      if (element.dataset.flyoutId) {
        document.getElementById(element.dataset.flyoutId).classList.add('has-border');
      }

      break;
    default:
      killFlyout();

      document.querySelector('.header__service-navigation').classList.remove('is-active');

      break;
  }

  activateFlyout();
}

export { mobileFlyout, navigateTo, killFlyout, slideTo };
